import React from 'react';
// import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
const CourseHead = () => {
  return (
    <div className="mt-5">
      <div className="hurye "></div>
      <div className="">
        <div className=" exppd">
          <div className="container">
            <div className="exp">Explore Our Course Categories</div>
            <div className="d-flex justify-content-center">
              <div className="ynd">
                Start Designing Products that serves people, serves businesses
                from experts. Paquetes de autoedición y editores de páginas web
                usan el Lorem Ipsum como su texto por defecto, y al hacer una
                búsqueda .
              </div>
            </div>

            <div>
              <div className="d-flex justify-content-center mt-4">
                {/* <button className="all">All categories </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseHead;
