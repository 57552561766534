import React from 'react';
import './Settings.css';
import PersonalInfo from '../../components/PersonalInfo';

const Settings = () => {
  return (
    <div className="hfhfhfh">
      <div className="container">
        <PersonalInfo />
      </div>
    </div>
  );
};

export default Settings;
