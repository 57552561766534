import React from 'react'

const Nothing = () => {
  return (
    <div>
      <div>Nothing</div>
      <div>Nothing</div>
      <div>Nothing</div>
      <div>Nothing</div>
      <div>Nothing</div>
      <div>Nothing</div>
      <div>Nothing</div>
      <div>Nothing</div>
      <div>Nothing</div>
      <div>Nothing</div>
      <div>Nothing</div>
      <div>Nothing</div>
    </div>
  );
}

export default Nothing