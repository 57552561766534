import React from 'react';
import tes from './images/testi.png';
import tgirl from './images/tesgirl.png';
const Testimonals = () => {
  return (
    <div className="test_holders">
      <div>
        <div className="Testimonials">
          <span>Testimonials</span>
        </div>
        <div className="container">
          <div className="d_flex">
            <div className="boxesd">
              <div className="row">
                {/* <div className="col-12 col-lg-6">
                  <div className="image_rectangle">
                    <img src={tgirl} alt="" className="image_rectangle" />
                  </div>
                </div> */}
                <div className="col-12 ">
                  <div className="what_to_say">
                    “From writing and design, to maximizing engagement with
                    carousels and Reels, Learn Chris Do's exact process for make
                    attention.”
                  </div>
                  <div>
                    <div className="ballz">
                      <div className="image_ave">
                        <img src={tes} alt="" className="image_ave" />
                      </div>
                      <div>
                        <div className="namexz">
                          <span>Tina Snow</span>
                        </div>
                        <div className="rolex">UX Designer, 🇳🇬 Nigeria</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" boxesd">
              <div className="row">
                {/* <div className="col-12 col-lg-6">
                  <div className="image_rectangle">
                    <img src={tgirl} alt="" className="image_rectangle" />
                  </div>
                </div> */}
                <div className="col-12 ">
                  <div className="what_to_say">
                    “From writing and design, to maximizing engagement with
                    carousels and Reels, Learn Chris Do's exact process for make
                    attention.”
                  </div>
                  <div>
                    <div className="ballz">
                      <div className="image_ave">
                        <img src={tes} alt="" className="image_ave" />
                      </div>
                      <div>
                        <div className="namexz">
                          <span>Tina Snow</span>
                        </div>
                        <div className="rolex">UX Designer, 🇳🇬 Nigeria</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="boxesd">
              <div className="row">
                {/* <div className="col-12 col-lg-6">
                  <div className="image_rectangle">
                    <img src={tgirl} alt="" className="image_rectangle" />
                  </div>
                </div> */}
                <div className="col-12 ">
                  <div className="what_to_say">
                    “From writing and design, to maximizing engagement with
                    carousels and Reels, Learn Chris Do's exact process for make
                    attention.”
                  </div>
                  <div>
                    <div className="ballz">
                      <div className="image_ave">
                        <img src={tes} alt="" className="image_ave" />
                      </div>
                      <div>
                        <div className="namexz">
                          <span>Tina Snow</span>
                        </div>
                        <div className="rolex">UX Designer, 🇳🇬 Nigeria</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonals;
