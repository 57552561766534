import React from 'react';
const CourseOnly = () => {
  return (
    <div>
      <div className="hurye "></div>
    </div>
  );
};

export default CourseOnly;
