import React from 'react';

const AllPastWebinarHead = () => {
  return (
    <div className="container loyall">
      <div className="exploreour">Explore Our Past Webinar</div>
      <div className="hgutd">
        <div className="startDes">
          Start Designing Products that serves people, serves businesses from
          experts. Paquetes de autoedición y editores de páginas web usan el
          Lorem Ipsum como su texto por defecto, y al hacer una búsqueda .
        </div>
      </div>
    </div>
  );
};

export default AllPastWebinarHead;
