import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../firebase';
import Skeleton from '@mui/material/Skeleton';

const ComingSoon = () => {
  const [comingSoonData, setComingSoonData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchComingSoonData();
  }, []);

  const fetchComingSoonData = async () => {
    try {
      const adminQuery = query(collection(db, 'Admin'));
      const adminSnapshot = await getDocs(adminQuery);

      if (!adminSnapshot.empty) {
        const allComingSoon = adminSnapshot.docs.reduce((accumulator, doc) => {
          const data = doc.data();
          const comingSoonArray = data.comingSoon || [];
          return [...accumulator, ...comingSoonArray];
        }, []);

        setComingSoonData(allComingSoon);
      }
    } catch (error) {
      console.error('Error fetching coming soon data:', error);
    } finally {
      setLoading(false);
    }
  };

  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  const anim = (e) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.2 });
  };

  const anim2 = (e) => {
    gsap.from(e.target, { scale: 1.2 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    <div className="going">
      <div className="container">
        <div className="ava">Coming Soon</div>

        <div className="putyty">
          <div className="flex-up" ref={scrl} onScroll={scrollCheck}>
            {loading ? (
              <>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={400}
                  height={200}
                  style={{
                    borderRadius: '12px',
                    background: '#f0f0f0cc',
                    marginRight: '19px',
                  }}
                />
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={400}
                  height={200}
                  style={{
                    borderRadius: '12px',
                    background: '#f0f0f0cc',
                    marginRight: '19px',
                  }}
                />
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={400}
                  height={200}
                  style={{
                    borderRadius: '12px',
                    background: '#f0f0f0cc',
                    marginRight: '19px',
                  }}
                />
              </>
            ) : (
              <>
                {comingSoonData.length === 0 ? (
                  <>
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width={400}
                      height={200}
                      style={{
                        borderRadius: '12px',
                        background: '#f0f0f0cc',
                        marginRight: '19px',
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width={400}
                      height={200}
                      style={{
                        borderRadius: '12px',
                        background: '#f0f0f0cc',
                        marginRight: '19px',
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width={400}
                      height={200}
                      style={{
                        borderRadius: '12px',
                        background: '#f0f0f0cc',
                        marginRight: '19px',
                      }}
                    />
                  </>
                ) : (
                  comingSoonData.map((item) => (
                    <div
                      className="tiemsd"
                      style={{
                        backgroundImage: `url(${item.selectedImage})`,
                      }}
                    >
                      <div className="container">
                        <div className="cs">Coming Soon</div>
                        <div className="push-down">
                          <div className="cname">{item?.tutorName}</div>
                          <div className="ctitle">{item?.courseName}</div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-2">
        {/* <button className="view">VIEW MORE</button> */}
      </div>
    </div>
  );
};

export default ComingSoon;
