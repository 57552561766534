import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { BsFillCircleFill, BsCalendar3, BsClock } from 'react-icons/bs';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../firebase';
import Skeleton from '@mui/material/Skeleton';
import y from '../pages/Webinars/yng.png';
import { Link } from 'react-router-dom';
import c1 from '../pages/images/classes.png';
import c2 from '../pages/images/classes2.png';
import c3 from '../pages/images/classes3.png';
import c4 from '../pages/images/classes4.png';
const UpcomingWebinar = () => {
  const [comingSoonData, setComingSoonData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchComingSoonData();
  }, []);

  const fetchComingSoonData = async () => {
    try {
      const adminQuery = query(collection(db, 'Admin'));
      const adminSnapshot = await getDocs(adminQuery);

      if (!adminSnapshot.empty) {
        const allComingSoon = adminSnapshot.docs.reduce((accumulator, doc) => {
          const data = doc.data();
          const comingSoonArray = data.upcomingWebinar || [];
          return [...accumulator, ...comingSoonArray];
        }, []);

        setComingSoonData(allComingSoon);
      }
    } catch (error) {
      console.error('Error fetching coming soon data:', error);
    } finally {
      setLoading(false);
    }
  };

  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  const anim = (e) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.2 });
  };

  const anim2 = (e) => {
    gsap.from(e.target, { scale: 1.2 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    <div className="upcomingHolder">
      <div className="going">
        <div className="container">
          <div className="ava mb-4">Upcoming Webinars</div>

          <div className="putyty">
            {/* <div className="man">
              {scrollX !== 0 && (
                <div className="gogo">
                  <div
                    className="next"
                    onClick={() => slide(-300)}
                    onMouseEnter={(e) => anim(e)}
                    onMouseLeave={(e) => anim2(e)}
                  >
                    <MdArrowBackIosNew />
                  </div>
                </div>
              )}
            </div> */}
            <div className="flex-up" ref={scrl} onScroll={scrollCheck}>
              <>
                <div>
                  <img src={c1} alt="" />
                </div>
                <div>
                  <img src={c2} alt="" />
                </div>
                <div>
                  <img src={c3} alt="" />
                </div>
                <div>
                  <img src={c4} alt="" />
                </div>
                <div>
                  <img src={c1} alt="" />
                </div>
                <div>
                  <img src={c2} alt="" />
                </div>
                <div>
                  <img src={c3} alt="" />
                </div>
                <div>
                  <img src={c4} alt="" />
                </div>
                <div>
                  <img src={c1} alt="" />
                </div>
                <div>
                  <img src={c2} alt="" />
                </div>
                <div>
                  <img src={c3} alt="" />
                </div>
                <div>
                  <img src={c3} alt="" />
                </div>
                <div>
                  <img src={c4} alt="" />
                </div>
                <div>
                  <img src={c1} alt="" />
                </div>
                <div>
                  <img src={c2} alt="" />
                </div>
                <div>
                  <img src={c3} alt="" />
                </div>
                <div>
                  <img src={c4} alt="" />
                </div>
              </>
            </div>
            {/* <div className="man">
              {!scrolEnd && (
                <div className="gogo2">
                  <div
                    className="next2"
                    onClick={() => slide(+300)}
                    onMouseEnter={(e) => anim(e)}
                    onMouseLeave={(e) => anim2(e)}
                  >
                    <MdArrowForwardIos />
                  </div>
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingWebinar;
