import React, { useState, useEffect } from 'react';
import { BsFillCircleFill, BsCalendar3, BsClock } from 'react-icons/bs';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../firebase';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '403px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const WebinarHead = () => {
  const [comingSoonData, setComingSoonData] = useState([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    fetchComingSoonData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentItemIndex((prevIndex) =>
        prevIndex === comingSoonData.length - 1 ? 0 : prevIndex + 1
      );
    }, 1 * 60 * 1000); // 3 minutes

    return () => {
      clearTimeout(timer);
    };
  }, [currentItemIndex, comingSoonData]);

  const fetchComingSoonData = async () => {
    try {
      const adminQuery = query(collection(db, 'Admin'));
      const adminSnapshot = await getDocs(adminQuery);

      if (!adminSnapshot.empty) {
        const allComingSoon = adminSnapshot.docs.reduce((accumulator, doc) => {
          const data = doc.data();
          const comingSoonArray = data.upcomingWebinar || [];
          return [...accumulator, ...comingSoonArray];
        }, []);

        setComingSoonData(allComingSoon);
      }
    } catch (error) {
      console.error('Error fetching coming soon data:', error);
    }
  };

  if (comingSoonData.length === 0) {
    return null; // If no data is available, don't render anything
  }

  const currentItem = comingSoonData[currentItemIndex];

  return (
    <div className="blimet">
      {/* <div
        className="firstName"
        style={{
          backgroundImage: `url(${currentItem?.selectedWebinarBanner})`,
        }}
      >
        <div className="container">
          <div className="pt-5">
            <div className="what">{currentItem?.courseName}</div>
            <div className="mt-5">
              <div className="livebig">
                {' '}
                <BsFillCircleFill className="circledd2" />{' '}
                <span className="liveWeb2">Live Webinar</span>
              </div>
            </div>
            <div className="hfugy">
              <div className="hfugy2">
                <BsCalendar3 className="calender" />
                <div className="dates">{currentItem?.webinarDate}</div>
              </div>
              <div className="hfugy2">
                <BsClock className="calender" />
                <div className="dates">{currentItem?.WebinarTime}</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="firstName">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="shepp">
                <div className="livebig mb-4">
                  {' '}
                  <BsFillCircleFill className="circledd2" />{' '}
                  <span className="liveWeb2">Live Webinar</span>
                </div>
                <div className="what mb-3">{currentItem?.courseName}</div>
                <div className="skiii mb-4">
                  From writing and design, to maximizing engagement with
                  carousels and Reels, Learn Chris Do's exact process for make
                  attention-grabbing Instagram content meant to grow your
                  industry authority.
                </div>
                <div className="mb-4">
                  <div className="date_time_holder">
                    <div>
                      <div className="dates">{currentItem?.webinarDate}</div>
                    </div>
                    <div>
                      {' '}
                      <div className="dates">{currentItem?.WebinarTime}</div>
                    </div>
                  </div>
                </div>
                <div>
                  <button className="watch_trailer" onClick={handleOpen}>
                    Watch Trailer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default WebinarHead;
