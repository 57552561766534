import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import a from '../../Students/pages/images/trending.webp';
const Trending = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    searchTrendingCourses();
  }, []);

  const searchTrendingCourses = async () => {
    const trendingCourseIds = [];

    // Retrieve the student collection
    const studentQuerySnapshot = await getDocs(collection(db, 'student'));

    // Create an object to track the count of each course ID
    const courseIdCountMap = {};

    // Iterate over each student document
    studentQuerySnapshot.forEach((studentDoc) => {
      const purchasedCourses = studentDoc.data()?.purchasedCourses || [];

      // Iterate over the purchasedCourses of the current student
      purchasedCourses.forEach((course) => {
        const courseId = course.courseId;
        courseIdCountMap[courseId] = (courseIdCountMap[courseId] || 0) + 1;
      });
    });

    // Check for the first course ID that appears more than once across different students
    Object.entries(courseIdCountMap).some(([courseId, count]) => {
      if (count > 1) {
        trendingCourseIds.push(courseId);
        return true; // Break out of the loop after finding the first trending course
      }
      return false;
    });

    console.log('Trending Course IDs:', trendingCourseIds);
    // Retrieve the Admin collection
    const adminQuerySnapshot = await getDocs(collection(db, 'Admin'));
    const adminData = adminQuerySnapshot.docs[0]?.data();

    const trendingCourses = [];

    // Iterate over the Allcourses object
    Object.values(adminData?.Allcourses || {}).forEach((coursesArray) => {
      coursesArray.some((course) => {
        // Check if courseId matches the first captured trending courseIds
        if (trendingCourseIds.includes(course.courseId)) {
          // This is a trending course
          trendingCourses.push(course);
          return true; // Break out of the loop after finding the first trending course
        }
        return false;
      });
    });

    // Set the courses state with the trending courses
    setCourses(trendingCourses);
  };

  return (
    <div className="woit">
      {courses.length === 0 ? (
        // Default page when no trending courses available
        <div>
          <div className="rtyu">
            <div className="owns">
              <div className="row">
                <div className="col-12 col-lg-5">
                  <div className="leftTrending ">
                    <div className="tennnn">Trending Course</div>
                    <div className="desss">Design Thinking</div>
                    <div className="texttxx">
                      From writing and design, to maximizing engagement with
                      carousels and Reels, Learn Chris Do's exact process for
                      make attention-grabbing Instagram content meant to grow
                      your industry authority.
                    </div>
                    <div className="prihfuu">N5000</div>
                    <div className="buttonLinkk">
                      <button className="enroll">ENROLL NOW</button>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-7">
                  <div className="imageside">
                    <img src={a} alt="" className="imageside" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Render trending courses
        <>
          <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
            {courses.map((course) => (
              <div className="" key={course.courseId}>
                <div className="rtyu">
                  <div className=" owns">
                    <div className="container">
                      <div className="row conforrr">
                        <div className="col-12 col-lg-5">
                          <div className="leftTrending ">
                            <div className="tennnn">Trending Course</div>
                            <div className="desss">{course.nameOfCourse}</div>
                            <div className="texttxx">
                              {course.courseDescription.length > 300
                                ? `${course.courseDescription.slice(0, 300)}...`
                                : course.courseDescription}
                            </div>
                            <div className="prihfuu">
                              ₦ {parseFloat(course.price).toLocaleString()}
                            </div>
                            <div className="buttonLinkk">
                              <Link to={`/course-preview/${course.courseId}`}>
                                <button className="enroll">ENROLL NOW</button>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-7">
                          <div className="eboor">
                            <div className="imageside">
                              <img src={a} alt="" className="imageside" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </>
      )}
    </div>
  );
};

export default Trending;
