import React, { useState, useEffect } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import c1 from '../pages/images/classes.png';
import c2 from '../pages/images/classes2.png';
import c3 from '../pages/images/classes3.png';
import c4 from '../pages/images/classes4.png';
const UxResearch = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      // Query the Admin collection to retrieve all documents
      const adminQuery = query(collection(db, 'Admin'));
      const adminSnapshot = await getDocs(adminQuery);

      if (!adminSnapshot.empty) {
        const allCourses = adminSnapshot.docs.reduce((accumulator, doc) => {
          const data = doc.data();
          const categoryCourses = data.Allcourses
            ? Object.entries(data.Allcourses)
            : [];
          return [...accumulator, ...categoryCourses];
        }, []);

        // Set the courses state with the retrieved array
        setCourses(allCourses);
      }
    };

    fetchCourses();
  }, []);

  return (
    <>
      {/* <div className="res">
        <div className="container">
          {courses.map(([categoryName, categoryCourses]) => (
            <div key={categoryName}>
              <div className="uxx">{categoryName}</div>
              <div className="row mt-5">
                {categoryCourses?.slice(0, 8).map((course) => (
                  <div className="col-12 col-lg-3  mb-5" key={course.id}>
                    <div className="wsisisi">
                      <Link
                        to={`/course-preview/${course.courseId}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <div
                          className="linearr"
                          style={{
                            backgroundImage: `url(${course.thumbnailURL})`,
                          }}
                        >
                          <div className="change">
                            <div className="anita">{course.tutorName}</div>
                            <div className="ux">{course.nameOfCourse}</div>
                            <div className="hour">{course.Duration}</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-center mt-4 mb-4">
                <Link
                  to={{
                    pathname: '/course-category',
                    search: `?category=${categoryName}`,
                  }}
                >
                  <button className="view">VIEW MORE</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <div className="container all_green">
        <div className="long_road">
          <div className="fitzz">
            <div className="course_title">
              <span>UX Research</span>
            </div>
            <div>
              <button className="see_more">See More</button>
            </div>
          </div>
          <div className="four_all">
            <div className="row">
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c1} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c2} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c3} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c4} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="long_road">
          <div className="fitzz">
            <div className="course_title">
              <span>UX Research</span>
            </div>
            <div>
              <button className="see_more">See More</button>
            </div>
          </div>
          <div className="four_all">
            <div className="row">
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c1} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c2} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c3} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c4} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="long_road">
          <div className="fitzz">
            <div className="course_title">
              <span>UX Research</span>
            </div>
            <div>
              <button className="see_more">See More</button>
            </div>
          </div>
          <div className="four_all">
            <div className="row">
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c1} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c2} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c3} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c4} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="long_road">
          <div className="fitzz">
            <div className="course_title">
              <span>UX Research</span>
            </div>
            <div>
              <button className="see_more">See More</button>
            </div>
          </div>
          <div className="four_all">
            <div className="row">
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c1} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c2} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c3} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c4} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="long_road">
          <div className="fitzz">
            <div className="course_title">
              <span>UX Research</span>
            </div>
            <div>
              <button className="see_more">See More</button>
            </div>
          </div>
          <div className="four_all">
            <div className="row">
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c1} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c2} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c3} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-5">
                <div>
                  <div className="col_image_ho">
                    <img src={c4} alt="" className="col_image_ho" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UxResearch;
